<template>
  <div class="batch-search">
    <div class="top-box">
      <div class="top-title">学生档案表</div>
    </div>
    <!--  -->
    <div class="table-container-body">
      <el-table :data="tableData" height="660" stripe class="table-body"
      :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
      v-loading="tableLoading">
        <el-table-column prop="id" label="序号" width="80">
          <template slot-scope="scope">
            <span>{{(pageNo - 1) * pageSize + scope.$index + 1}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="year" label="年份"></el-table-column>
        <el-table-column prop="province" label="省份"></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="subject" label="选科"></el-table-column>
        <el-table-column prop="score" label="分数"></el-table-column>
        <el-table-column prop="isPay" label="支付状态" width="80">
          <template slot-scope="scope" >
            <el-tag v-if="scope.row.isPay == 0" type="info">未支付</el-tag>
            <el-tag v-else-if="scope.row.isPay == 1" type="success">已支付</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="操作" width="80">
          <template slot-scope="scope">
            <div class="table-btn-box">
              <div class="table-btn" @click="handleUpdateClick(scope.row)">
                修改分数
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="填报方案" width="80">
          <template slot-scope="scope">
            <div class="table-btn-box">
              <div class="table-btn" @click="handleStartClick(scope.row)">
                开始
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-container-footer">
      <el-pagination
        layout="total, prev, pager, next, sizes, jumper"
        :page-sizes="[10, 50, 100, 500]"
        :page-size="pageSize"
        :total="tableTotal"
        :current-page="pageNo"
        @current-change="pageChange"
        @size-change="sizeChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="修改分数"
      width="20%"
      :visible.sync="editDialogVisible"
      :before-close="handleAddClose"
    >
      <el-row>
        <el-col :span="20" class="add-col">
          <div class="add-label">
            <div class="required-label">*</div>
            分数
          </div>
          <div class="add-input-box">
            <el-input
              v-model="editScore"
              placeholder="请输入分数"
              class="form-control" 
            ></el-input>
          </div>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <div class="btn-box">
          <div class="btn-dialog-cancel" @click="editDialogVisible = false">关闭</div>
          <div class="btn-dialog-confirm" @click="handleUpdateSaveClick()">保存</div>
        </div>
      </span>
    </el-dialog>
    <el-dialog
      title="填报支付"
      :visible.sync="startDialogVisible"
      width="15%"
      :before-close="handlePayClose"
      center>
      <el-input
        v-model="discountCode"
        maxlength="6"
        placeholder="请输入优惠码"
        class="form-control" 
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCreateOrderClick('skip')">跳 过</el-button>
        <el-button type="primary" @click="handleCreateOrderClick()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="订单支付"
      :visible.sync="payDialogVisible"
      :before-close="handlePayClose"
      width="16%"
      center>
      <div class="qr-container">
        <span>请使用微信扫一扫支付,本窗口会在支付成功后自动关闭</span>
        <img :src="QRImgUrl" />
        <span>若支付后长时间未响应请点击'我已支付'</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cleanPayInfo()">取消支付</el-button>
        <el-button type="primary" @click="refreshOrderStatus()">我已支付</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import QRCode from 'qrcode'
export default {
  name: "batch",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      pageNo: 1,
      pageSize:10,
      tableTotal:0,
      tableData: [],
      editId:"",
      editScore:"",
      editTimes:0,
      adminType:"",
      discountCode:"",
      currentPayId:"",
      QRImgUrl:"",
      QRinterval: null,
      outTradeNo:"",
      tableLoading:false,
      editDialogVisible: false,
      startDialogVisible: false,
      payDialogVisible: false,
    };
  },
  methods: {
    initSelectData(){
      
    },
    initTableData(){
      this.tableLoading=true;
      var data={
        currentPage: this.pageNo,
        pageSize: this.pageSize,
      }
      //获取分页表格数据
      this.$http.post(this.$apis.getArchiveList,data).then((res) => {
        this.tableLoading=false;
        if (res.data) {
          this.tableData = res.data.list.map(r=>{
            return {
              id:r.id,
              year: r.year,
              province: r.provinceName,
              name:r.name,
              subject: r.course1+" "+r.course2+" "+r.course3,
              couldUpdateTimes: r.couldUpdateTimes,
              score: r.score,
              isPay: r.isPay
            }
          });
          this.tableTotal = res.data.total;
        }
      });
    },
    handleSearchClick(){
      this.pageNo = 1;
      this.initTableData();
    },
    handleUpdateClick(row){
      if(this.adminType !=0 && this.adminType !=2 && (!row.couldUpdateTimes || row.couldUpdateTimes <=0)){
         this.$message.error("修改次数不足，无法修改！");
        return;
      }
      this.editDialogVisible = true;
      this.editId = row.id;
      this.editScore = row.score;
      this.editTimes =row.couldUpdateTimes;
    },
    handleUpdateSaveClick(){
       if(!this.editId || this.editId === ""){
         this.$message.error("账户不能为空");
        return;
      } if(!this.editScore || this.editScore === ""){
         this.$message.error("分数不能为空");
        return;
      }
      var updateTimes = this.editTimes - 1;
      var data ={
        id:this.editId,
        score:this.editScore,
        updateTimes:updateTimes < 0 ? 0 : updateTimes,
      };
      this.$http.post(this.$apis.updateStudentScore,data).then(res=>{
       if (res.code==200 && res.message==="SUCCESS") {
            this.$message.success("修改成功!");
        }
        else{
            this.$message.error("修改失败!原因:"+res.message);
        }
        this.editTimes = 0;
        this.editId = "";
        this.editScore = "";
        this.editDialogVisible = false;
        this.initTableData();
      });
    },
    handleStartClick(row){
      if(row.isPay == 1 || this.adminType === "0" || this.adminType === "2"){
        this.jumpToApplicationInfo(row.id);
      }else{
        this.discountCode = "";
        this.currentPayId = row.id;
        this.startDialogVisible = true;
      }
    },
    handleCreateOrderClick(type){
      let loading = this.$loading({ fullscreen: true });
      var url = this.$apis.createOrder+"?学生档案ID="+this.currentPayId;
      if(type !== 'skip'){
        if(!this.discountCode || this.discountCode === "" || this.discountCode.length !== 6 || !/^\d+(?=\.{0,1}\d+$|$)/.test(this.discountCode)){
          loading.close();
          this.$message.error("请输入正确6位数字的优惠码!");
          return;
        }
        url += "&优惠码="+this.discountCode;
      }
      this.$http.post(url).then(res=>{
        if (res.code==200 && res.message==="SUCCESS") {
          this.startDialogVisible = false;
          this.payDialogVisible = true;
          QRCode.toDataURL(res.data.qrCodeUrl, { errorCorrectionLevel: 'L', margin: 2, width: 256 }, (err, url) => {
            loading.close();
            if (err){
              throw err;
            } 
            this.outTradeNo = res.data.outTradeNo;
            this.QRImgUrl = url
            this.QRinterval = setInterval(() =>{
              setTimeout(this.refreshOrderStatus(), 0);
            },3000);
          });
        }else{
          this.$message.error("错误:"+res.message);
          loading.close();
        }
      });
    },
    
    handleAddClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.editId = "";
          this.editScore = "";
          done();
        })
        .catch((_) => {});
    },
    handlePayClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.cleanPayInfo();
          done();
        })
        .catch((_) => {});
    },
    pageChange(val) {
      this.pageNo = val;
      this.initTableData();
    },
    sizeChange(val){
      this.pageSize = val;
      this.initTableData();
    },
    refreshOrderStatus(){
      if(this.outTradeNo){
        this.$http.post(this.$apis.getOrderStatus+"?outTradeNo="+this.outTradeNo).then(res=>{
          if(res.code==200 && res.message==="SUCCESS"){
            if(res.data.tradeState === "SUCCESS" && res.data.successTime && res.data.payerAmount && res.data.payerOpenid){
              this.$message.success("支付成功!");
              var studentId = this.currentPayId;
              this.cleanPayInfo();
              this.jumpToApplicationInfo(studentId);
            }
          }else{
            this.$message.error("支付异常!请重新支付!原因:"+res.message);
            this.cleanPayInfo();
          }
        });
      }else{
        this.$message.error("支付异常!请重新支付!");
        this.cleanPayInfo();
      }
    },
    jumpToApplicationInfo(studentId){
      this.$router.push({
        name: "applicationInfo",
        params:{
          studentId: studentId
        }
      });
    },
    cleanPayInfo(){
      let loading = this.$loading({ fullscreen: true });
      this.payDialogVisible = false;
      this.startDialogVisible = false;
      this.QRImgUrl = "";
      this.currentPayId = "";
      this.discountCode = "";
      clearInterval(this.QRinterval);
      this.QRinterval = null;
      loading.close();
    },
  },
  mounted(){
    this.adminType = this.$store.getters.getAdminInfo.adminType.toString();
    this.initSelectData();
    this.initTableData();
    this.cleanPayInfo();
  },
};
</script>
<style lang="less" scoped>
.batch-search {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 5px;
  border: 1px solid rgba(230, 230, 230, 100);
  .add-col {
    padding: 15px 0px;
    display: flex;
    align-items: center;
    .add-label {
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
      width: 120px;
      .required-label {
        color: rgb(255, 0, 0);
      }
    }
    .add-input-box {
      display: flex;
      width: calc(100% - 100px);
    }
    .view-text-box{
      height: 40px;
      display: flex;
      align-items: center;
      width: calc(100% - 100px);
    }
  }
  .dialog-footer{
    display: flex;
    justify-content: flex-end;
    .btn-box {
      display: flex;
      align-items: center;
      .btn-dialog-cancel {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: #fff;
        text-align: center;
        color: #000;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
        border: 1px solid #c9c9c9;
      }
      .btn-dialog-confirm {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border-bottom: 1px solid rgba(230, 230, 230, 100);

    .top-title {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      font-weight: 700;
      padding-left: 20px;
      position: relative;
    }
    .top-title::after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 27px;
      width: 3px;
      background-color: #ff450b;
    }
    .btn-box {
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
      .btn-dialog-cancel {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: #fff;
        text-align: center;
        color: #000;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
        border: 1px solid #c9c9c9;
      }
      .btn-dialog-confirm {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
  .form-group{
      padding: 0px 20px;
    .form-item {
      display: flex;
      align-items: center;
      padding: 15px 0;
      .label {
        display: flex;
        justify-content: flex-end;
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 70px;
        padding-right:10px;
      }
      .input-box {
          display: flex;
          width: calc(100% - 100px);
      }
      .required-label{
            color: rgb(255, 0, 0);
      }
      .action-link{
          font-size: 16px;
          cursor:pointer;
          color: #ff450b;
          padding-right: 20px;
      }
    }
  }
  .form-control{
      width: 100%;
  }
  .table-container-body {
    .table-body{
      width: 100%;
      overflow: auto;
    }
    .table-btn-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .table-btn {
        cursor: pointer;
        width: 60px;
        line-height: 28px;
        border-radius: 14px;
        color: rgba(121, 160, 241, 100);
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(121, 160, 241, 100);
      }
    }
  }
  .table-container-footer{
    padding: 5px 15px;
  }
  .bottom-box{
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    .btn-box {
      padding: 0px 20px;
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
  .qr-container{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
  }
}
</style>